@keyframes rotate4 {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash4 {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 100, 200;
        stroke-dashoffset: -15px;
    }
    100% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: -125px;
    }
}

.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff; /* Warna background yang lembut */
}

.spinner {
    width: 3.25em;
    transform-origin: center;
    animation: rotate4 2s linear infinite;
}

.spinner-circle {
    fill: none;
    stroke: rgb(255, 143, 0);
    stroke-width: 2;
    stroke-dasharray: 1, 200;
    strokeLinecap: round;
    animation: dash4 1.5s ease-in-out infinite;
}

.loading-text {
    margin-top: 8px;
    color: rgb(255, 143, 0);
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    opacity: 0.9;
}
