@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-inter;
}

.leaflet-pane {
    z-index: 0 !important;
}
.leaflet-top,
.leaflet-bottom {
    z-index: 10 !important;
}
