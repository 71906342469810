.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #3B82F6 #F3F4F6;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #F3F4F6;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #3B82F6;
    border: 3px solid #F3F4F6;
  }